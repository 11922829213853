import React from "react"

import { Box, Center } from "@chakra-ui/layout"
import grayZiptiLogo from "../../../images/grayZiptiLogo.svg"
import { Image } from "@chakra-ui/image"
import ZiptiText from "../zipti_text"
import { Link } from "gatsby"

const Footer: React.FC = props => (
  <Box {...props} bgColor="#fefeff" h="10vh">
    <Center bgColor="#fefeff">
      <Image src={grayZiptiLogo} />
    </Center>
    <Center bgColor="#fefeff">
      <ZiptiText pt="2vh" fontSize="1.5vh">
        <Link to="/jobs">Jobs </Link>
        <Link to="/privacy">Privacy </Link>
        <Link to="/terms">Terms </Link>
        <Link to="/help">Help </Link>
      </ZiptiText>
    </Center>
    <Center pt="1vh" pb="8vh" bgColor="#fefeff">
      <ZiptiText color="gray.300" fontSize="1.25vh">
        @2021 Zipti, Inc.
      </ZiptiText>
    </Center>
  </Box>
)

export default Footer
