import React from "react"

import Wrapper from "../shared/wrapper"
import ZiptiText from "../shared/zipti_text"
import SentMessage from "../shared/conversation/sent_message"
import ReceivedMessage from "../shared/conversation/received_message"
import IconEvent from "../shared/conversation/icon_event"

interface ExpertConversationProps {
  profileName: string
  timeSinceCopy: string
}

const ExpertConversation: React.FC<ExpertConversationProps> = props => {
  return (
    <Wrapper>
      <ZiptiText mt="3vh" h="1.5vh" fontWeight="bold">
        {props.profileName}
      </ZiptiText>
      <ReceivedMessage mt="3vh" timeSinceCopy={props.timeSinceCopy} />
      <IconEvent
        mt="3vh"
        eventType="paid"
        eventCopy="Mica paid you $35"
        eventDate="October 20"
      />
      <IconEvent
        mt="3vh"
        eventType="watched"
        eventCopy="You watched Mica's question!"
        eventDate="October 21"
      />
      <SentMessage mt="3vh" timeSinceCopy={props.timeSinceCopy} />
      <IconEvent
        mt="3vh"
        eventType="answered"
        eventCopy="You answered Mica's question!"
        eventDate="October 21"
      />
      <IconEvent
        mt="3vh"
        eventType="thanks"
        eventCopy="Mica said thanks!"
        eventDate="October 22"
      />
    </Wrapper>
  )
}

export default ExpertConversation
