import React, { useContext, useState } from "react"
import { Link, navigate } from "gatsby"
import {
  Box,
  Flex,
  Image,
  Spacer,
  Divider,
  Avatar,
  VStack,
  Button,
} from "@chakra-ui/react"
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react"
import ziptiLogo from "../../../images/ziptiLogo.svg"
import { ConversationsContext, UserContext } from "../../user_wrapper"

const Header: React.FC = (props: any) => {
  const user = useContext(UserContext)
  const conversations = useContext(ConversationsContext)
  return (
    <VStack height="8rem" justifyContent="center" bgColor="white" {...props}>
      <Image
        onClick={async () => {
          await conversations.resetConversations()
          navigate("/home")
        }}
        position="absolute"
        top="1rem"
        h="5vh"
        w="5vh"
        borderRadius="full"
        src={ziptiLogo}
      />
      <Menu>
        <MenuButton
          backgroundColor={"rgb(0,0,0,0)"}
          position="absolute"
          right="1rem"
          top=".5rem"
          as={Button}
          rightIcon={
            !user.data?.is_anonymous ? (
              <Avatar
                h="3vh"
                w="3vh"
                src={user.data?.account?.profile_image_url}
              />
            ) : undefined
          }
        ></MenuButton>
        <MenuList>
          <MenuItem>
            <Link to="/signout">Sign Out</Link>
          </MenuItem>
        </MenuList>
      </Menu>

      <Divider borderBottomWidth="0.5vh" />
    </VStack>
  )
}

export default Header
