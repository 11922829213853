import React from "react"
import { Box } from "@chakra-ui/layout"
import Header from "./header"
import Footer from "./footer"

const Wrapper: React.FC<any> = (props: any) => (
  <Box display="flex" flexDirection="column" bgColor="#fefeff" {...props}>
    <Header {...props?.headerProps} />
    {props.children}
    <Footer pt="5vh" {...props?.footerProps} />
  </Box>
)

export default Wrapper
